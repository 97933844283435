<template>
  <app-menu key="shipping-menu" :items="items">
    <template #activator="{ attrs, on }">
      <v-btn class="btn-menu" v-bind="attrs" v-on="on" plain>
        {{ $t("shipping-menu") }}
        <app-chevron-down />
      </v-btn>
    </template>
  </app-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShippingMenu",
  components: {
    AppBtn: () => import("@/components/core/app/Btn"),
    AppMenu: () => import("@/components/core/app/Menu"),
    AppChevronDown: () => import("@/components/core/app/ChevronDown"),
  },
  data: () => ({
    options: [
      {
        heading: "Checkin",
        role: ["*Driver"],
      },
      {
        title: "Dashboard",
        to: "/shipping/dashboard",
        role: ["*Driver"],
      },
      {
        title: "Checkin/Registro",
        to: "/shipping/checkin/type",
        role: ["*Driver"],
      },
      {
        divider: true,
        role: ["*Driver"],
      },
      {
        title: "Historic/Historico",
        to: "/shipping/historic",
        role: ["*Driver"],
      },
      {
        title: "Close Report Inbound",
        to: "/shipping/close/report/inbound",
        role: ["*Driver"],
      },
      {
        title: "Close Report Outbound",
        to: "/shipping/close/report/outbound",
        role: ["*Driver"],
      },
      {
        heading: "Create",
        role: ["*Driver"],
      },
      {
        title: "Create PU/Crear PU",
        to: "/shipping/outbound",
        role: ["*Driver"],
      },
      {
        title: "Create PO/Crear PO",
        to: "/shipping/inbound",
        role: ["*Driver"],
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth", "user"]),
    items() {
      const filtered = this.options.filter((record) => {
        let result =
          record.role == undefined ||
          ((!record.role.some((role) => role.indexOf("*") === -1) ||
            record.role.some((role) => role === this.user.role)) &&
            !record.role.some((role) => role === "*" + this.user.role));
        return result;
      });
      return filtered;
    },
  },
};
</script>
